import './caro.css'
import { v4 as uuidv4 } from "uuid";
import Card from "./card";
import Carousel from "./Carousel";

function MainCarousel() {
  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card imagen="https://i.ibb.co/DRzDMbQ/ANU-1017-1-min.jpg"  timing=""/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen="https://i.ibb.co/mBYN0h3/ANU-1091-1-min.jpg"   timing=""/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen="https://i.ibb.co/hHW9pg1/Anant-Ambani-Ki-Fiance-Is-Cute.webp" timing=""/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen="https://i.ibb.co/DRzDMbQ/ANU-1017-1-min.jpg"  timing=""/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen="https://i.ibb.co/mBYN0h3/ANU-1091-1-min.jpg" timing=""/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen="https://i.ibb.co/hHW9pg1/Anant-Ambani-Ki-Fiance-Is-Cute.webp" timing=""/>
      )
    }
  ];
  return (
    <>
    <div className="caro-caro">
    <p className="text-center md:mb-14">
        <span className="heading heading1 font-Michroma">ENGA</span>
        <span className="heading heading2 font-Michroma">GEMENTS</span>
      </p>
      <Carousel
        cards={cards}
        height="500px"
        width="30%"
        margin="0 auto"
        offset={2}
        showArrows={false}
      />
    </div>
    </>
  );
}

export default MainCarousel;
