export const routes = [
  {
    title: "About",
    href: "#about",
  },
  {
    title: "Tracks",
    href: "#tracks",
  },
  {
    title: "Prizes",
    href: "#prizes",
  },
  {
    title: "Contact Us",
    href: "#footer",
  },
];
